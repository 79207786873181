import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import LoginContext from './LoginContext';

function Navbar() {

	const { user } = useContext(LoginContext);

	return (
		<nav className="navbar navbar-inverse">
			<div className="container d-flex justify-content-between align-items-center">
				<div className="navbar-header">
					<Link
						to='/'
						className='display-1 text-decoration-none '
						style={{ color: '#1F271B' }}
					>
						IW Cars
					</Link>
				</div>
				{!user && (
					<div className="d-flex">
						<Link to='/login'>
							<button className="btn btn-success me-2">Log In</button>
						</Link>
						<Link to='/signup'>
							<button className="btn btn-danger">Sign Up</button>
						</Link>
					</div>
				)}

			</div>
		</nav>
	);
}

export default Navbar;