import React, { createContext, useState, useMemo } from 'react';

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
	const [user, setUser] = useState(null); // login state

	const login = (userData) => setUser(userData); // login function
	const logout = () => setUser(null); // logout function

	const value = useMemo(() => ({ user, login, logout }), [user, login, logout]);

	return (
		<LoginContext.Provider value={value}>
			{children}	{/* App component */}
		</LoginContext.Provider>
	);
};

export default LoginContext;
