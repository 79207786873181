import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import colors from '../sort_colors.json';

function SearchFilters() {
	const [makeOptions, setMakeOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [submodelOptions, setSubmodelOptions] = useState([]);
	const [colorOptions, setColorOptions] = useState([]);
	const [selectedMake, setSelectedMake] = useState(null);
	const [selectedModel, setSelectedModel] = useState(null);

	// Fetch Make options on page load
	useEffect(() => {
		const fetchMakeOptions = async () => {
			try {
				const response = await fetch('https://iwcarsapi.azurewebsites.net/api/getmake?basic=true');
				let data = await response.json();
				data.sort((a, b) => a.make.localeCompare(b.make));	//sort makes by name asc
				setSubmodelOptions([]);	//! bug shit ain't refreshing the component			
				setMakeOptions(data);
			} catch (error) {
				//console.error('Error fetching make options:', error);
			}
		};

		fetchMakeOptions();
	}, []);

	// Fetch Model options when Make is selected
	useEffect(() => {
		if (selectedMake) {
			const fetchModelOptions = async () => {
				try {
					const response = await fetch(`https://iwcarsapi.azurewebsites.net/api/getmodel?basic=true&make=${selectedMake}`);
					let data = await response.json();
					data.sort((a, b) => a.model.localeCompare(b.model));	//sort base models by name asc
					setModelOptions(data);
				} catch (error) {
					console.error('Error fetching model options:', error);
				}
			};

			fetchModelOptions();
		} else {
			setModelOptions([]);
		}
	}, [selectedMake]);

	// Fetch SubModel options when Model is selected
	useEffect(() => {
		if (selectedModel) {
			const fetchSubmodelOptions = async () => {
				try {
					const response = await fetch(`https://iwcarsapi.azurewebsites.net/api/getsubmodel?basic=true&model=${selectedModel}`);
					let data = await response.json();
					data.sort((a, b) => a.submodel.localeCompare(b.submodel));	//sort models by name asc
					setSubmodelOptions(data);
				} catch (error) {
					console.error('Error fetching submodel options:', error);
				}
			};

			fetchSubmodelOptions();
		} else {
			setSubmodelOptions([]);
		}
	}, [selectedModel]);

	useEffect(() => {
		// Load colors from colors.json
		setColorOptions(colors);
	}, []);

	return (
		<Container className="my-4">
			<h2 className="text-center mb-4">Filter Your Car Specifications</h2>
			<Form>
				<Row className="gy-3">
					<Col md={4}>
						<Form.Select
							className='text-center'
							onChange={(e) => setSelectedMake(e.target.value)}
							value={selectedMake || ""}
							defaultValue=""
						>
							<option value="" disabled hidden>Make</option>
							{makeOptions.map((make) => (
								<option key={make._id} value={make._id}>
									{make.make} {/* Matches the API response */}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md={4}>
						<Form.Select
							className='text-center'
							onChange={(e) => setSelectedModel(e.target.value)}
							value={selectedModel || ""}
							disabled={!selectedMake}
							defaultValue=""
						>
							<option value="" disabled hidden>Base Model</option>
							{modelOptions.map((model) => (
								<option key={model._id} value={model._id}>
									{model.model} {/* Adjust if the field name is different */}
								</option>
							))}
						</Form.Select>
					</Col>
					<Col md={4}>
						<Form.Select
							className='text-center'
							disabled={!selectedModel}
							defaultValue=""
						>
							<option value="" disabled hidden>Model</option>
							{submodelOptions.map((submodel) => (
								<option key={submodel._id} value={submodel._id}>
									{submodel.submodel} {/* Adjust if the field name is different */}
								</option>
							))}
						</Form.Select>
					</Col>
					{/* Repeat the above structure for other filters */}
				</Row>
				<br />
				<Row className="gy-3">
					<Col md={3}>
						<Form.Select className='text-center' defaultValtue="">
							<option value="" disable hidden>Kilometers (From)</option>
							{(() => {
								const kilometerOptions = [];
								for (let kilometer = 1000; kilometer < 10000; kilometer += 1000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 10000; kilometer < 50000; kilometer += 5000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 50000; kilometer < 100000; kilometer += 10000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 100000; kilometer < 1000000; kilometer += 100000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 1000000; kilometer < 10000000; kilometer += 1000000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 10000000; kilometer <= 100000000; kilometer += 10000000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								return kilometerOptions;
							})()}
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Kilometers (To)</option>
							{(() => {
								const kilometerOptions = [];
								for (let kilometer = 1000; kilometer < 10000; kilometer += 1000) {
									kilometerOptions.push(


										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 10000; kilometer < 50000; kilometer += 5000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 50000; kilometer < 100000; kilometer += 10000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 100000; kilometer < 1000000; kilometer += 100000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 1000000; kilometer < 10000000; kilometer += 1000000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								for (let kilometer = 10000000; kilometer <= 100000000; kilometer += 10000000) {
									kilometerOptions.push(
										<option key={kilometer} value={kilometer}>
											{kilometer}km
										</option>
									);
								}
								return kilometerOptions;
							})()}
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Year (From)</option>
							{(() => {
								const yearOptions = [];
								const currentYear = new Date().getFullYear();
								for (let year = currentYear; year >= 1886; year--) {
									yearOptions.push(
										<option key={year} value={year}>
											{year}
										</option>
									);
								}
								return yearOptions;
							})()}

						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Year (To)</option>
							{(() => {
								const yearOptions = [];
								const currentYear = new Date().getFullYear();
								for (let year = currentYear; year >= 1886; year--) {
									yearOptions.push(
										<option key={year} value={year}>
											{year}
										</option>
									);
								}
								return yearOptions;
							})()}
						</Form.Select>
					</Col>
				</Row>
				<br />
				<Row className="gy-3">
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Price (From)</option>
							{(() => {
								const priceOptions = [];
								for (let price = 1000; price < 10000; price += 1000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 10000; price < 50000; price += 5000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 50000; price < 100000; price += 10000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 100000; price < 1000000; price += 100000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 1000000; price < 10000000; price += 1000000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 10000000; price <= 100000000; price += 10000000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								return priceOptions;
							})()}
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Price (To)</option>
							{(() => {
								const priceOptions = [];
								for (let price = 1000; price < 10000; price += 1000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 10000; price < 50000; price += 5000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 50000; price < 100000; price += 10000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 100000; price < 1000000; price += 100000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 1000000; price < 10000000; price += 1000000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								for (let price = 10000000; price <= 100000000; price += 10000000) {
									priceOptions.push(
										<option key={price} value={price}>
											{price}€
										</option>
									);
								}
								return priceOptions;
							})()}
						</Form.Select>

					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Horse Power(From)</option>
							{(() => {
								const hpOptions = [];
								for (let hp = 10; hp < 100; hp += 10) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								for (let hp = 100; hp < 1000; hp += 100) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								for (let hp = 1000; hp < 10000; hp += 1000) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								return hpOptions;
							})()}
						</Form.Select>
					</Col>

					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Horse Power(To)</option>
							{(() => {
								const hpOptions = [];
								for (let hp = 10; hp < 100; hp += 10) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								for (let hp = 100; hp < 1000; hp += 100) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								for (let hp = 1000; hp < 10000; hp += 1000) {
									hpOptions.push(
										<option key={hp} value={hp}>
											{hp}hp
										</option>
									);
								}
								return hpOptions;
							})()}
						</Form.Select>
					</Col>
				</Row>
				<br />
				<Row className="gy-3">

					<Col md={3}>
						<Form.Select className='text-center' defaultValue=''>
							<option value='' disabled hidden>Paint Effect</option>
							<option value='Glow-in-the-Dark'>Glow-in-the-Dark</option>
							<option value='Thermochromic'>Thermochromic</option>
							<option value='Photochromic'>Photochromic</option>
							<option value='Metal-Flake'>Metal-Flake</option>
							<option value='Sparkle'>Sparkle</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue=''>
							<option value='' disabled hidden>Paint treatment</option>
							<option value='Vinyl'>Vinyl</option>
							<option value='PPF'>PPF</option>
							<option value='Wax'>Wax</option>
							<option value='Ceramic Coating'>Ceramic Coating</option>
							<option value='Graphene Coatings'>Graphene Coatings</option>
							<option value='Rubberized Coatings'>Rubberized Coatings</option>
							<option value='Nano Coating'>Nano Coating</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue=''>
							<option value='' disabled hidden>Paint Finish</option>
							<option value='Plain'>Plain</option>
							<option value='Metallic'>Metallic</option>
							<option value='Iridescent'>Iridescent</option>
							<option value='Glossy'>Glossy</option>
							<option value='Satin'>Satin</option>
							<option value='Candy'>Candy</option>
							<option value='Chameleon'>Chameleon</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden >Paint color</option>
							{colorOptions.map((color, index) => (
								<option
									key={index}
									value={color.hex}
									style={{ backgroundColor: color.hex, color: "white" }} // Set background and text color
								>
									{color.color}
								</option>
							))}
						</Form.Select>
					</Col>
				</Row>
				<br />
				<Row className="gy-3">
					<Col md={3}>

						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Car Servicing </option>
							<option>Up To Date</option>
							<option>Overdue</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="" >
							<option value=""
								disabled
								hidden>VAT Deductible</option>
							<option>Yes</option>
							<option>No</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Financing</option>
							<option>Up Front</option>
							<option>Loan</option>
							<option>Credit</option>
							<option>Leasing</option>
							<option>Long Term Rental</option>
							<option>Ballon</option>
							<option>Personal Contract</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Accept Retake</option>
							<option>Yes</option>
							<option>No</option>
						</Form.Select>
					</Col>
				</Row>
				<br />
				<Row className="gy-3">
					<Col md={3}>
						<Form.Select className='text-center' defaultValue="">
							<option value="" disabled hidden>Secondary Key</option>
							<option>Yes</option>
							<option>No</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select
							className='text-center' defaultValue="">
							<option value="" disabled hidden>Fuel</option>
							<option value='Electric'>Electric</option>
							<option value='Gas (Hybrid)'>Gas (Hybrid)</option>
							<option value='Diesel (Hybrid)'>Diesel (Hybrid)</option>
							<option value='Gas'>Gas</option>
							<option value='Diesel'>Diesel</option>
							<option value='Black Vodka'>Black Vodka</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select
							className='text-center' defaultValue="">
							<option value="" disabled hidden>Power Train</option>
							<option value='AWL'>AWL</option>
							<option value='4WL'>4WL</option>
							<option value='RWL'>RWL</option>
							<option value='FWL'>FWL</option>
						</Form.Select>
					</Col>
					<Col md={3}>
						<Form.Select className='text-center' defaultValue=''>
							<option value="" disabled hidden >Origin Country</option>
							<option value='Moçambique'>Moçambique</option>
							<option value='Angola'>Angola</option>
							<option value='Turquia'>Turquia</option>
							<option value='Rússia'>Rússia</option>
							<option value='China'>China</option>
							<option value='Japão'>Japão</option>
							<option value='Brasil'>Brasil</option>
							<option value='Hungria'>Hungria</option>
							<option value='Bulgária'>Bulgária</option>
							<option value='Roménia'>Roménia</option>
							<option value='Nova Zelândia'>Nova Zelândia</option>
							<option value='Austrália'>Austrália</option>
							<option value='EAU'>EAU</option>
							<option value='EUA'>EUA</option>
							<option value='Estónia'>Estónia</option>
							<option value='Eslováquia'>Eslováquia</option>
							<option value='Eslovénia'>Eslovénia</option>
							<option value='Polónia'>Polónia</option>
							<option value='Alemanha'>Alemanha</option>
							<option value='Holanda'>Holanda</option>
							<option value='Suiça'>Suiça</option>
							<option value='Itália'>Itália</option>
							<option value='França'>França</option>
							<option value='Espanha'>Espanha</option>
							<option value='Portugal'>Portugal</option>
							<option value='Argentina'>Argentina</option>
						</Form.Select>
					</Col>
				</Row>

				<br />


				{/* Add more rows and dropdowns as necessary */}
				<div className="text-center mt-4">
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</div>
			</Form>
		</Container>
	);
}

export default SearchFilters;

/*
	brand
	model
	submodel
	motor

	year from
	year to

	price from
	price to
	negotiable price

	engine size from
	engine size to

	todo horsepower from
	todo horsepower to

	todo kilometer from
	todo kilometer to

	number of doors

	number of passengers

	wheel drive

	color

	type of color

	segment

	drive train

	fuel

	car servicing

	vat deductible

	financing

	Accepts retake

	todo country of origin

	------------------------- extra -------------------------

	todo secondary key
*/