import React, { useState, useContext } from "react";
import LoginContext from "./LoginContext"; // Import your context

function Login() {

	const { login } = useContext(LoginContext);

	// State to store form input values
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");


	const handleSubmit = async (e) => {
		e.preventDefault(); // prevent browser default submit

		try {
			// fetch
			const response = await fetch("https://iwcarsapi.azurewebsites.net/api/userauth", {
				method: "POST",
				header: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ "username": username, "password": password })
			});
			console.log("response status code: " + response.status);

			if (response.status !== 206) {
				<div className="alert alert-danger alert-dismissible">
					Login failed. Try again
					<button type="button" class="btn-close" data-bs-dismiss="alert"></button>
				</div>
			} else if (response.status === 206) {
				const user = await response.json();
				console.log("user" + user);

				login(user);
			}

		} catch (error) {
			<div className="alert alert-success alert-dismissible">
				Login failed Try again
				<button type="button" className="btn-close" data-bs-dismiss="alert"></button>
			</div>
		}
	}

	return (
		<div className="container mt-5">
			<h2>Login</h2>
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<label
						htmlFor="username"
						className="form-label"
					>
						Username
					</label>
					<input
						type="text"
						className="form-control"
						id="username"
						placeholder="Enter your username"
						onChange={(e) => setUsername(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label
						htmlFor="password"
						className="form-label"
					>
						Password
					</label>
					<input
						type="password"
						className="form-control"
						id="password"
						placeholder="Enter your password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button type="submit" className="btn btn-primary">Login</button>
			</form>
		</div>
	);
}

export default Login;
